import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'
import axios from 'axios'
import VueAxios from 'vue-axios'

import CountPage from './pages/CountPage'
import RemotePage from './pages/RemotePage'


const router = new VueRouter({
    routes: [
        {
            path: "/",
            name: "CountPage",
            component: CountPage
        },
        {
            path: "/remote",
            name: "RemotePage",
            component: RemotePage
        }
    ]
})

Vue.config.productionTip = false
Vue.use(VueRouter)
Vue.use(VueAxios, axios)

new Vue({
    router: router,
    render: h => h(App),
}).$mount('#app')
