<template>
  <div class="remotepage">
    <loading :active="is_updating" :is-full-page="fullPage"></loading>

    <div class="header">
      <div class="logo"><img src="jugendfeier.png" height="60"/></div>
      <div class="status"></div>
    </div>

    <div class="remote-number">
      {{ current_number }}
    </div>

    <div class="remote-buttons">
      <div class="button-row">
        <button v-on:click="incrementNumber(-1)" class="disable-dbl-tap-zoom">-1</button>
        <button v-on:click="incrementNumber(-10)" class="disable-dbl-tap-zoom">-10</button>
        <button v-on:click="incrementNumber(10)" class="disable-dbl-tap-zoom">+10</button>
        <button v-on:click="incrementNumber(1)" class="disable-dbl-tap-zoom">+1</button>
      </div>

    </div>

  </div>
</template>

<script>
import axios from "axios"
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

export default {
  name: 'RemotePage',
  components: {Loading},
  data() {
    return {
      current_number: 0,
      msg: null,
      is_updating: false,
      fullPage: true
    }
  },
  methods: {
    incrementNumber: function (amount) {
      this.current_number += amount
      this.is_updating = true
    }
  },
  watch: {
    is_updating: function () {
      axios.post('https://pd74a91afa2.dev.team-wd.de/backend/update.php', {
        'current_number': this.current_number
      }).then(() => {
        this.is_updating = false
      })
    }
  },
  mounted() {
    axios.get("https://pd74a91afa2.dev.team-wd.de/backend/update.php")
    .then(response => this.current_number = parseInt(response.data.current_number))
  }
}
</script>

<style scoped>
.logo {
  padding: 10px 15px;
}

.remotepage {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.header {
}

.remote-number {
  font-size: 30vh;
  color: #fff;
  text-align: center;
  flex-grow: 4;
}

.remote-buttons {
  padding: 2em 0 2em;
}

.button-row {
  display: flex;
  justify-content: space-around;
}

button {
  width: 20vw;
  height: 60px;
}

.disable-dbl-tap-zoom {
  touch-action: manipulation;
}

</style>
