<template>
  <div class="countpage">
    <div class="logo">
      <img src="jugendfeier.png"/>
      <button v-on:click="playSound()" v-if="showPlayButton">Play</button>
    </div>
    <div class="pulseicon-box">
      <div class="pulseicon-text">
        <transition name="bounce" enter-active-class="bounceIn" leave-active-class="bounceOutLeft">
          <div :key="next_number">
            {{ next_number }}
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios"
import "vue2-animate/dist/vue2-animate.css"

export default {
  name: 'CountPage',
  data() {
    return {
      next_number: 0,
      msg: null,
      pollInterval: null,
      pollIntervalTime: 2000,
      polling: false,
      soundfile: null,
      showPlayButton: false
    }
  },
  methods: {
    updateValue() {
      axios.get("https://pd74a91afa2.dev.team-wd.de/backend/update.php")
          .then(
              response => this.next_number = parseInt(response.data.current_number)
          )
    },
    startStatusPoll() {
      this.pollInterval = setInterval(() => {
        this.updateValue()
      }, this.pollIntervalTime)
    },
    stopStatusPoll() {
      clearInterval(this.pollInterval)
    },
    playSound() {
      this.showPlayButton = false
      this.soundfile.play()
    }
  },
  mounted() {
    this.soundfile = new Audio('bell.aac')
    this.startStatusPoll()
  },
  watch: {
    next_number: function() {
      this.soundfile.play().then().catch(error => {
        this.showPlayButton = true
        console.log(error)
      })
    }
  }
}
</script>

<style scoped>
.logo {
  margin-bottom: 2em;
  width: 100%;
  text-align: center;
}

.countpage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.pulseicon-box {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pulseicon-text {
  animation: pulseicon 1s alternate ease-in-out infinite;
  transition: all .2s ease-in-out;
  background: linear-gradient(151deg, rgba(233, 233, 233, 1) 0%, rgba(255, 255, 255, 1) 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: #FFF;
  font-size: 50vh;
  font-weight: bold;
  text-align: center;
  text-shadow: 0 0 5px rgba(0,0,0,.7);
}

@-webkit-keyframes pulseicon {
  from {
    transform: scale(1)
  }
  to {
    transform: scale(1.03)
  }
}

@keyframes pulseicon {
  from {
    transform: scale(1)
  }
  to {
    transform: scale(1.03)
  }
}

</style>
