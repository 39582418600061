<template>
  <div id="app">
    <router-view class="view"></router-view>
  </div>
</template>

<script>

export default {
  name: 'App'
}
</script>

<style>

body {
  padding: 0;
  margin: 0;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #EE7203;
  position: fixed;
  height: 100%;
  width: 100%;
}
</style>
